import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Meta from "../components/Meta"
import Layout from "../components/Layout"
import Container from "../components/common/Container"
import RichText from "../components/RichText"
import BlogFrame from "../components/BlogFrame"
import PageHeader from "../components/PageHeader"
import SliceMachine from "../slices/SliceMachine"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { sm, md, xl } from "../utils/breakpoints"

const CategoryMargin = styled.div`
  margin-bottom: 3rem;
`

const Image = styled.div`
  margin-bottom: -14rem;
  position: relative;
  z-index: 3;

  @media ${md} {
    position: absolute;
    top: 19rem;
    right: 10%;
    margin-bottom: 0;
  }
`

const LastCategoryMod = styled.div`
  > div:last-of-type > div {
    border-bottom: none;
  }
`

const FrequentlyAskedQuestions = ({ data, pageContext, location }) => {
  const page = data.allPrismicFrequentlyaskedquestions.edges[0].node.data
  const categories = page.body
  return (
    <Layout
      location={location}
      prismicLayout={data.prismicLayout}
      prismicSiteBanner={data.prismicSitebanner}>
      <Meta title={"Frequently asked questions"} canonical={location.href} />
      <Container>
        <PageHeader headerText={<RichText render={page?.title?.richText} />}>
          <Image>
            <GatsbyImage image={getImage(page.image)} alt={page.image.text} />
          </Image>
        </PageHeader>
      </Container>
      <BlogFrame>
        <LastCategoryMod>
          <SliceMachine body={page.body} />
        </LastCategoryMod>
        <CategoryMargin />
      </BlogFrame>
    </Layout>
  )
}

export default FrequentlyAskedQuestions

export const postQuery = graphql`
  query {
    ...PrismicSiteBanner
    ...PrismicLayout
    allPrismicFrequentlyaskedquestions {
      edges {
        node {
          id
          data {
            title {
              richText
            }
            image {
              alt
              gatsbyImageData(
                height: 300
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            body {
              ... on PrismicFrequentlyaskedquestionsDataBodyFaqcategory {
                id
                slice_label
                slice_type
                primary {
                  category_title
                }
                items {
                  question
                  answer {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
